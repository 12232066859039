<template>
  <MasterDetail
    formTitle="Nota Fiscal/Item"
    :cols="cols"
    :opts="opts"
    :resourceUrl="resourceUrl"
    descriptionProperty="chave_nfe"
    :canEdit="false"
    :canDelete="false"
    :hasNewButton="false"
    :formWidth="1400"
    :selectedToCompare="selectedNF"
    :contextOptions="options"
  >
    <!--  <v-menu
      v-model="dateMenu"
      :close-on-click="true"
      :close-on-content-click="false"
      transition="scroll-y-transition"
      offset-y
      right
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          style="width: 250px"
          prefix="Competência:"
          :value="competencia | toFullMonth"
          dense
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          @click:prepend.stop="on.click"
          @click:clear="competencia = null"
          v-on="on"
          hide-details
          clearable
          class="mb-n1 mt-0"
        ></v-text-field>
      </template>
      <v-date-picker v-model="competencia" type="month" @click:month="dateMenu = false;" no-title></v-date-picker>
    </v-menu>-->
  </MasterDetail>
</template>

<script>
//import * as moment from "moment";
import MasterDetail from "@/components/master-detail.vue";
export default {
  components: {
    MasterDetail,
  },
  props: ["selectedNF"],
  computed: {
    resourceUrl: function () {
       return `/v1/faturamento/devolucao/${this.getClient().clientId}/de-para/${
        this.$route.params.nfId
      }`;
      /* return `/v1/faturamento/devolucao/${this.getClient().clientId}`; */
    },
    resource: function () {
      return this.apiResource(this.resourceUrl);
    },
    /* filterQuery: function () {
      return this.competencia && this.competencia != ""
        ? `competencia=${this.competencia}`
        : null;
    }, */
  },
  created: function () {
    //this.competencia = moment().format("YYYY-MM");
  },
  data: function () {
    return {
      dateMenu: false,
      //competencia: null,

      cols: [
        /* NFE */
        {
          name: "NFE",
          type: this.$fieldTypes.SUBTITLE,
        },

        {
          key: "num_doc",
          name: "Nº Doc",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
        },
        {
          key: "nat_op",
          name: "Natureza Operação",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 8,
        },
        {
          key: "chave_nfe",
          name: "Chave Nf-e",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 7,
        },
        {
          key: "data_emissao",
          name: "Dt Emissão",
          type: this.$fieldTypes.DATETIME,
          editable: false,
          colSize: 3,
        },
        {
          key: "serie",
          name: "Série",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 2,
        },
        /* Fim NFE */
        /* Item NFE */
        {
          name: "Item NFE",
          type: this.$fieldTypes.SUBTITLE,
        },

        {
          key: "cfop",
          name: "CFOP",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 2,
        },
        {
          key: "desc_cfop",
          name: "Descrição CFOP",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 6,
        },
        {
          key: "ncm",
          name: "NCM",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 2,
        },
        {
          key: "itemId",
          name: "Cód. Item",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 2,
        },
        {
          key: "descr_compl",
          name: "Desc. Complementar",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 6,
        },
        {
          key: "quantidade",
          name: "Quantidade",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_item",
          name: "Valor Item",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_frete",
          name: "Valor Frete",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_seg",
          name: "Valor Seg.",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_outros",
          name: "Valor Outros",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_desc",
          name: "Valor Desc",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        /* Fim Item NFE */
        /* ICMS */
        {
          name: "ICMS",
          type: this.$fieldTypes.SUBTITLE,
        },

        {
          key: "cst_icms",
          name: "CST ICMS",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_bc_icms",
          name: "Valor BC ICMS",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        {
          key: "aliq_icms",
          name: "Aliq. ICMS",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_icms",
          name: "Valor ICMS",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_icms_op",
          name: "Valor ICMS OP",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        {
          key: "p_dif",
          name: "P DIF",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_icms_dif",
          name: "Valor ICMS DIF",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_bc_icms_st",
          name: "Valor BC ICMS ST",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        {
          key: "aliq_st",
          name: "Aliq. ST",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_icms_st",
          name: "Valor ICMS ST",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        {
          key: "fcp_st",
          name: "FCP ST",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 3,
        },
        {
          key: "deson_icms",
          name: "Deson. ICMS",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 3,
        },
        {
          key: "difal_dest",
          name: "Difal Dest.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
        },
        {
          key: "difal_remet",
          name: "Difal Remet.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
        },
        {
          key: "difal_fcp",
          name: "Difal FCP",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
        },
        /* FIM ICMS */
        /* IPI */
        {
          name: "IPI",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "cst_ipi",
          name: "CST IPI",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
        },
        {
          key: "vl_bc_ipi",
          name: "Valor BC IPI",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 4,
        },
        {
          key: "aliq_ipi",
          name: "Aliq. IPI",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
        },
        {
          key: "vl_ipi",
          name: "Valor IPI",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 6,
        },
        {
          key: "cod_enq",
          name: "Cód. Enq.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 6,
        },
        /* FIM IPI */
        /* PIS COFINS */
        {
          name: "PIS COFINS",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "cst_pis_cofins",
          name: "CST PIS COFINS",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
        },
        {
          key: "vl_bc_pis_cofins",
          name: "Valor BC PIS COFINS",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 4,
        },
        {
          key: "aliq_pis",
          name: "Aliq. PIS",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
        },
        {
          key: "vl_pis",
          name: "Valor PIS",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 4,
        },
        {
          key: "aliq_cofins",
          name: "Aliq. COFINS",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
        },
        {
          key: "vl_cofins",
          name: "Valor COFINS",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 4,
        },
        /* FIM PIS COFINS */
        /* OUTRAS INFORMAÇÕES */
        {
          name: "OUTRAS INFORMAÇÕES",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "nome_emit",
          name: "Nome Emit.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 8,
        },
        {
          key: "cnpj_emit",
          name: "CNPJ Emit.",
          type: this.$fieldTypes.CNPJ,
          editable: false,
          colSize: 4,
        },
        {
          key: "nome_dest",
          name: "Nome Dest.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 8,
        },
        {
          key: "uf_dest",
          name: "UF Dest.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 2,
        },
        {
          key: "suframa_dest",
          name: "SUFRAMA Dest.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 2,
        },
        {
          key: "chave_ref",
          name: "Chave Ref.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 8,
        },
        {
          key: "valor_total_nf",
          name: "Valor Total NF",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 4,
        },
        {
          key: "inf_adic_prod",
          name: "Inf. Adic. Prod.",
          type: this.$fieldTypes.TEXTAREA,
          editable: false,
        },
        {
          key: "inf_comp",
          name: "Inf. Comp.",
          type: this.$fieldTypes.TEXTAREA,
          editable: false,
        },
        /* FIM OUTRAS INFORMAÇÕES */
        /* BASE DE ANÁLISE */
        {
          name: "BASE DE ANÁLISE",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "situacao_nf",
          name: "Situação NF",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "situacao_nf", key: "name", name: "name" },
          colSize: 6,
        },
        {
          key: "sit_incentivo",
          name: "Situação Incentivo",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "sit_incentivo", key: "name", name: "name" },
          colSize: 6,
        },
        {
          key: "ncm_incentivada",
          name: "NCM Incentivada",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "ncm_incentivada", key: "name", name: "name" },
          colSize: 6,
        },
        {
          key: "port_inf_compl",
          name: "Port. Inf. Compl.",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "port_inf_compl", key: "name", name: "name" },
          colSize: 6,
        },
        {
          key: "obs",
          name: "Obs.",
          type: this.$fieldTypes.TEXTAREA,
        },
        /* FIM BASE DE ANÁLISE */
        /* INFORMAÇÕES */
        {
          name: "INFORMAÇÕES",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "vlr_oper_integral",
          name: "Valor Oper. Integral",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 6,
        },
        {
          key: "vlr_oper",
          name: "Valor Oper.",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 6,
        },
        {
          key: "produto",
          name: "Produto",
          type: this.$fieldTypes.TEXT,
          colSize: 5,
        },
        {
          key: "modelo",
          name: "Modelo",
          type: this.$fieldTypes.TEXT,
          colSize: 5,
        },
        {
          key: "periodo",
          name: "Período",
          type: this.$fieldTypes.MONTH,
          colSize: 2,
        },
        /* FIM INFORMAÇÕES */
        /* CLASSIFICAÇÃO */
        {
          name: "CLASSIFICAÇÃO",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "cred_pres",
          name: "Cred. Pres.",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "cred_pres", key: "name", name: "name" },
          colSize: 6,
        },
        {
          key: "comp_ped",
          name: "Compromisso P&D",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "comp_ped", key: "name", name: "name" },
          colSize: 6,
        },

        /* {
          key: "criado_em",
          name: "Criado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        }, */
      ],
      opts: {
        situacao_nf: [
          { name: "NF TERCEIROS" },
          { name: "AUTORIZADA" },
          { name: "DENEGADA" },
          { name: "INUTILIZADA" },
          { name: "CANCELADA" },
        ],
        sit_incentivo: [{ name: "NÃO" }, { name: "PPB" }, { name: "TecNac" }],
        ncm_incentivada: [{ name: "SIM" }, { name: "NÃO" }],
        port_inf_compl: [
          { name: "PORTARIA CORRETA" },
          { name: "PORTARIA ERRADA" },
          { name: "SEM PORTARIA" },
          { name: "PRODUTO NÃO INCENTIVADO" },
        ],
        cred_pres: [
          { name: "SAÍDA INCENTIVADA" },
          { name: "DEVOLUÇÃO DE SAÍDA INCENTIVADA" },
          { name: "DEVOLUÇÃO DE SAÍDA NÃO INCENTIVADA" },
          { name: "OPERAÇÃO NÃO INCENTIVADA" },
        ],
        comp_ped: [
          { name: "VENDA INCENTIVADA" },
          { name: "DEVOLUÇÃO DE VENDA INCENTIVADA" },
          { name: "DEVOLUÇÃO DE VENDA NÃO INCENTIVADA" },
          { name: "VENDA ZFM" },
          { name: "DEVOLUÇÃO ZFM" },
          { name: "EXPORTAÇÃO" },
          { name: "DEVOLUÇÃO EXPORTAÇÃO" },
          { name: "OPERAÇÃO NÃO INCENTIVADA" },
        ],
      },
      options: [
        {
          name: "Conciliar",
          limit: 1,
          class: { "btn-info": true, "btn-md": true },
          cb: (e) => {
            this.showConfirm(
              "Confirmar Conciliação",
              `Deseja conciliar a nota de devolução '${this.selectedNF.num_doc}' a nota fiscal '${e.num_doc}'?`,
              () => {
                this.resource.save(
                  { nfDevolucaoId: this.selectedNF.nfId },
                  e.nfId
                ).then(() => {
                  //TODO msg conciliado com sucesso!
                });
              }
            );
          },
        },
      ],
    };
  },
};
</script>
